import axios from "axios"
import store from "@/store"

const apiUrl = store.getters.apiUrl
const appId = store.getters.appId
// const apiKey = store.getters.apiKey

axios.interceptors.request.use((config) => {
    let apiKey = localStorage.getItem("userKey")
 
    if (apiKey) {
        config.headers["x-app-id"] = appId
        config.headers["x-api-token"] = apiKey
    }

    return config
}, (error) => {
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 401) {
        // redirect to login page
        localStorage.removeItem('userInfo')
        localStorage.removeItem('userKey')
        console.log('REDIRECT?')
        window.location.href = "/app/sessions/login";
    }
    return Promise.reject(error.response)
})

class RestService {
    constructor(resource) {
        this.apiResource = resource
    }

    async getResources() {
        return await axios.get(`${apiUrl}/rest/${this.apiResource}`)
    }

    async getResourceByFilter(filter = '') {
        return await axios.get(`${apiUrl}/rest/${this.apiResource}${filter}`)
    }

    async getResource(id, filter = '') {
        // eslint-disable-next-line
        return await axios.get(`${apiUrl}/rest/${this.apiResource}/${id}${filter}`)
    }

    async postResource(data) {
        return await axios.post(`${apiUrl}/rest/${this.apiResource}`, data)
    }

    async putResource(id, data) {
        return await axios.put(`${apiUrl}/rest/${this.apiResource}/${id}`, data)
    }

    async deleteResource(id) {
        return await axios.delete(`${apiUrl}/rest/${this.apiResource}/${id}`)
    }

    async checkDuplicateName(name, collection_name) {
        // eslint-disable-next-line
        return await axios.get(`${apiUrl}/rest/${this.apiResource}/findByName?${collection_name}=${name}`)
    }

    async getCampaignVendorFees(object_id, object_type) {
        // eslint-disable-next-line
        return await axios.get(`${apiUrl}/rest/${this.apiResource}?object_id=${object_id}&object_type=${object_type}`)
    }

    async getSpecificCustomList(type) {
        // eslint-disable-next-line
        return await axios.get(`${apiUrl}/rest/${this.apiResource}?type=${type}`)
    }

    async getAdvertiserCampaign(advertiser_id) {
        // eslint-disable-next-line
        return await axios.get(`${apiUrl}/rest/${this.apiResource}/0?advertiser_id=${advertiser_id}`)
    }
}

export default RestService
