<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            Activity Logs
          </h6>
          <slot name="notificationDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>
    
    <div class="pa-5 mt-10">
      <div
        v-for="(item, index) in activity_logs"
        :key="index"
      >
        <div class="d-flex justify-space-between mb-8">
          <div class="d-flex">
            <v-avatar
              size="40"
              class="mr-2"
              :color="item.iconColor"
            >
              <v-icon :class="item.iconColorText">
                {{ item.iconName }}
              </v-icon>
            </v-avatar>
            <div>
              <p class="font-weight-medium body-2 ma-0">
                {{ item.title }}
              </p>
              <div>
                <span class="text--disabled">
                  {{ item.object_action }}D
                  <a
                    href="#"
                    class="caption"
                    @click="showItem(item.activity_id)"
                  >{{ item.object_type }}</a>
                </span>
              </div>
            </div>
          </div>
          <div>
            <span class="text--disabled caption">{{ item.time }}</span>
          </div>
        </div>
        <v-divider class="py-2" />
      </div>
    </div>
    <div class="d-flex justify-space-between flex-wrap">
      <template>
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Activity Log Detail</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="viewItem.id"
                      outlined
                      dense
                      disabled
                      label="Activity ID"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="viewItem.user_id"
                      outlined
                      dense
                      disabled
                      label="User ID"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="viewItem.action"
                      outlined
                      dense
                      disabled
                      label="Action"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="viewItem.object_type"
                      outlined
                      dense
                      disabled
                      label="Object"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="text-h6">
                      Details
                    </p>
                    <v-data-table
                      :headers="detail_headers"
                      :items="activityDetails(viewItem)"
                      class="elevation-1 table-one"
                      hide-default-footer
                      disable-pagination
                    >
                      <template
                        v-if="viewItem.action === 'UPDATE'"
                        #item="{ item }"
                      >
                        <tr :class="item.old ? 'yellow lighten-4' : ''">
                          <td :class="item.old ? 'font-weight-bold' : ''">
                            {{ item.field }}
                          </td>
                          <td :class="item.old ? 'font-weight-bold' : ''">
                            {{ item.value }}
                          </td>
                          <td :class="item.old ? 'font-weight-bold' : ''">
                            {{ item.old }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </div>
  </vue-perfect-scrollbar>
</template>
<script>
import store from "@/store"

import RestService from "@/services/RestService";
const ActivityLogsService = new RestService("activity_logs");

export default {
  data() {
    return {
      dialog: false,
      viewItem: {},
      detail_headers: [
        { text: "Field", value: "field" },
        { text: "Current", value: "value" },
        { text: "Previous", value: "old" },
      ],
    };
  },
  computed: {
    activity_logs() {
        return store.getters.activityLogs
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    activityDetails(item) {
      const details = _.map(item.details, (v, k) => {
        return {
          field: k,
          value: v,
          old: (item.differences && item.differences[k]) ? item.differences[k]['old_value'] : ''
        }
      })
      return details
    },
    showItem(id) {
      const activity_logs = ActivityLogsService.getResource(id)

      activity_logs.then((response) => {
        this.viewItem = Object.assign({}, response.data);
        this.dialog = true;
      })
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.viewItem = {};
      });
    },
  }
};
</script>
